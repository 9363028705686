import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Shop, shopSchema } from 'schema';
import { StringParam, useQueryParam } from 'use-query-params';

import { http } from 'services';

import { UpdateForm, updateSchema } from '../schema';
import { SHOP } from '..';

interface UpdateProps {
  defaultValues?: Partial<UpdateForm>;
  children: (props: UseFormReturn<UpdateForm>) => React.ReactNode;
}

const Update: React.FC<UpdateProps> = ({ children, defaultValues = {} }) => {
  const setShopId = useQueryParam('shopId', StringParam)[1];
  const client = useQueryClient();
  const form = useForm<UpdateForm>({ resolver: zodResolver(updateSchema), defaultValues: { ...defaultValues } });

  const mutation = useMutation<Shop, any, UpdateForm>({
    mutationFn: async ({ _id, ...values }) => {
      const { data } = await http.request.put(`/shops/${_id}`, values);
      const shop = shopSchema.parse(data?.shop);

      return shop;
    },
    onSuccess: async shop => {
      await client.invalidateQueries({ queryKey: SHOP.list({}).queryKey! });
      setShopId(undefined);
    }
  });

  const onSubmit = async (data: UpdateForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
      {children(form)}
    </form>
  );
};

export default Update;
