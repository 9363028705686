import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { Shop, shopSchema } from 'schema';

import { http } from 'services';

import { AddForm, addSchema } from '../schema';
import { SHOP } from '..';

interface AddProps {
  defaultValues?: Partial<AddForm>;
  children: (props: UseFormReturn<AddForm>) => React.ReactNode;
  onSuccess?: () => void;
}

const Add: React.FC<AddProps> = ({ children, defaultValues = {}, onSuccess }) => {
  const client = useQueryClient();
  const form = useForm<AddForm>({ resolver: zodResolver(addSchema), defaultValues: { ...defaultValues } });

  const mutation = useMutation<Shop, any, AddForm>({
    mutationFn: async values => {
      const { data } = await http.request.post<{ shop: Shop }>('/shops', values);
      const shop = shopSchema.parse(data?.shop);

      return shop;
    },
    onSuccess: async shop => {
      client.setQueryData(SHOP.list({}).queryKey, ({ shops }) => ({ shops: [shop, ...shops] }));
      message.success("Do'kon muvaffaqiyatli yaratildi");
      form.reset();
      onSuccess!();
    }
  });

  const onSubmit = async (data: AddForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit, errors => {
        console.log('errors = ', errors);
      })}
      className="w-full"
    >
      {children(form)}
    </form>
  );
};

export default Add;
